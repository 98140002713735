import PropTypes from "prop-types";
import React, { useState } from "react";

import formatDate from "~utils/date_utils/format_date/format_date";

import { LINK, MEDIA } from "../../../../prop_types";
import RscHubCardCoverImage from "../rsc_hb_card_cover_image/rsc_hb_card_cover_image";
import RscHbCardInner from "../rsc_hb_card_inner/rsc_hb_card_inner";
import RscHbCardWrapper from "../rsc_hub_card_wrapper/rsc_hub_card_wrapper";

export default function RscHbCardNews({
  date,
  link,
  cover,
  outlet,
  subtitle,
  title,
}) {
  const [isActive, setIsActive] = useState(false);

  let metadata = [];
  if (date && outlet) {
    metadata = [formatDate(date), outlet].filter(Boolean);
  }

  return (
    <RscHbCardWrapper
      hasCard
      link={link}
      isActive={isActive}
      setIsActive={setIsActive}
      data-testid="rsc-hb-card-news"
    >
      <RscHubCardCoverImage roundTop cover={cover} isActive={isActive} />
      <RscHbCardInner addPadding {...{ title, subtitle, metadata }} />
    </RscHbCardWrapper>
  );
}

RscHbCardNews.defaultProps = {
  outlet: "",
  cover: undefined,
  date: "",
  subtitle: "",
};

RscHbCardNews.propTypes = {
  outlet: PropTypes.string,
  cover: MEDIA,
  date: PropTypes.string,
  link: LINK.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};
